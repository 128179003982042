import { AUTH_COOKIE_OPTIONS, REDIRECT_COOKIE_NAME } from '#shared/cookies'

export default defineNuxtRouteMiddleware((to) => {
  const { loggedIn } = useUserSession()

  if (!loggedIn.value && to.path !== '/logg-inn') {
    const redirectCookie = useCookie(REDIRECT_COOKIE_NAME, {
      ...AUTH_COOKIE_OPTIONS,
      httpOnly: false,
    })

    redirectCookie.value = to.fullPath

    return navigateTo('/logg-inn')
  }
})
