import type { CookieOptions } from '#app'

export const REDIRECT_COOKIE_NAME = 'nuxt-redirect'

export const AUTH_COOKIE_OPTIONS = {
  secure: true,
  httpOnly: true,
  sameSite: 'lax',
  maxAge: 60 * 10, // 10 minutes
} as const satisfies CookieOptions
